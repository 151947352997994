import styled from 'styled-components'
import { SideMenuContainer } from "../components/side_menu/Container";
import { useContext } from "react";
import { GlobalContext } from "../../../App";

const StyledComponent = styled.div`
  width: 100%;
  max-width: 25.875rem;
  height: 56rem;
  position: relative;
  background-color: var(--color-white-1);
  
  @media(max-width:450px){
      height: 100vh;
  }
`;
export const ContentWrapper = ({ children }) => {
    const { showSideMenu, setShowSideMenu } = useContext(GlobalContext)
    return (
        <StyledComponent onClick={(e) => {
            if (showSideMenu) {
                setShowSideMenu(false)
            }
        }}>
            <>
                {children}
                {showSideMenu && <SideMenuContainer />}
            </>
        </StyledComponent>
    )
}
import styled from 'styled-components'
import { SizedBox } from "../../../global/components/SizedBox";
import { Link } from "react-router-dom";
import { NumericalKeyBoardContainer } from "../../../global/components/numerical_keyboard/Container";
import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../../../App";
import { numberWithCommas } from '../../../../helpers/numberWithCommas';

const StyledObject = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);

  .displayPalette {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;


    .accountBalance {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.218125rem;
      color: var(--color-label-200);
    }

    .typedInput {
      font-size: 4.5rem;
      font-weight: 600;
     /*
      display: flex;
      justify-items:center;
      align-items: center;
      */
      text-align: center;
      color: var(--color-label-50);
      line-height: 4.75rem;
      width: 100%;
      border:none;
      outline: none;

      &.active {
        color: var(--color-label-400);
      }
    }

    * {
      text-decoration: none;
    }

    .buttonWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 0 1.5rem;
      width: 100%;
      margin-top: auto;

      .buttonItem {
        color: var(--color-gray-100);
        background-color: ${({ theme }) => theme?.inActive};
        font-weight: 600;
        font-size: 1rem;
        line-height: 1rem;
        cursor: pointer;
        border-radius: 0.375rem;
        height: 3.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
          color:  ${({ theme }) => theme?.themedText};
          background-color:  ${({ theme }) => theme?.accent};
          font-size: 1rem;
          line-height: 1rem;
          font-weight: 600;
        }
      }
    }
  }


  .fundWithWrapper {
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
      margin-bottom: 0.7rem;

    .fundWithText {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;

      .cardStart {
        font-weight: 600;
        line-height: 1.25rem;
      }
    }

    .changeCardText {
      font-weight: 600;
      line-height: 1.25rem;
      font-size: 1rem;
      color:  ${({ theme }) => theme?.accent};
      text-decoration: none;
    }
  }
`;


export const Body = () => {
  const { topUpState, setTopUpState, userToken } = useContext(GlobalContext)
  const [randomNumberToHighlight, setRandomNumberToHighlight] = useState(null)

  useEffect(() => {
    if (!topUpState.amountToTopUp || topUpState.amountToTopUp.length < 3) {
      setRandomNumberToHighlight(Math.ceil(Math.random() * 9))
    } else {
      setRandomNumberToHighlight(null)
    }
  }, [topUpState])


  const home = `/${userToken}`

  const handleReceiveButtonClick = (e) => {
    let { value } = { ...e.target }
    value = value.split('')
      .filter(val => val !== ',' && val !== '$')
      .join('')

    if (value < 0 || value > 9999 || isNaN(Number(value))) return 0
    setTopUpState(prev => ({ ...prev, amountToTopUp: value }))
  }
  // const handleInputChange = () => {

  // }
  return (
    <StyledObject>
      <div className={"displayPalette"}>
        <div className={"accountBalance"}>
          Account balance : $6,790.37
        </div>
        <SizedBox height={15.625} />
        <input className={"typedInput " + (topUpState.amountToTopUp ? "active" : "")}
          placeholder={'$'}
          onChange={handleReceiveButtonClick}
          value={'$' + numberWithCommas(topUpState.amountToTopUp)}
        />
        <div className={"buttonWrapper"}>
          <Link to={topUpState.amountToTopUp ? (home + '/topup/success') : '#'}
            className={"buttonItem needActiveValidation " + (topUpState.amountToTopUp ? "active " : " ") + ((topUpState.amountToTopUp && topUpState.amountToTopUp.length >= 3) ? "highlighted-border " : " ")}>
            Top Up
          </Link>
        </div>
      </div>



      <div className="fundWithWrapper">
        <span className="fundWithText">
          Fund with <span className="cardStart">*7983</span>
        </span>
        <Link className="changeCardText" to={home + '/topup/selectcard'}>
          Change
        </Link>

      </div>
      {/* <NumericalKeyBoardContainer callback={handleReceiveButtonClick} keyToHighlight={randomNumberToHighlight} /> */}
    </StyledObject>
  )

}
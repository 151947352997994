import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes";
import { createContext, useEffect, useState } from "react";
import 'react-device-frameset/styles/marvel-devices.css'
import { ThemeProvider } from "styled-components";
import depositsLogo from './components/global/components/side_menu/static/logo.PNG'
import { flex, getTextColor } from "@deposits/ui-kit-react";

import { getThemeLogo } from "./helpers/api/theme";
import { getRouteTokenFromUrl } from "./helpers/getRouteToken";
import { Oval } from "react-loader-spinner";



export const GlobalContext = createContext({})


const defaultTheme = {
    // primary: "",
    // secondary: "",
    // accent: "",
    primary: "#0DB9E9",
    secondary: "#0D7FE9",
    accent: "#0DB9E9",
    themedText: 'black',
    primaryBlur: '#0DB9E930'
};

export const initailMoveMoneyConfig = {
    amountToSend: '',
    receivingBankName: 'Wells Fargo',
    receivingAccountNumber: null,
    receivingCurrencySymbol: '$',
    receivingRecipientName: null
}

export const initialInternationalTransferConfig = {
    fromCountryCode: 'usa',
    fromCountryCurrencyCode: 'USD',
    fromCountryCurrencySymbol: '$',
    toCountryCode: 'eu',
    toCountryCurrencyCode: 'EUR',
    toCountryCurrencySymbol: '&#8364',
    amountToTransfer: null,
    recipientAccountName: null,
    exchangeRate: null,
    destinationIBANNumber: null
}

export const topUpConfig = {
    amountToTopUp: '',
    accountName: null,
}



const App = () => {

    const [showSideMenu, setShowSideMenu] = useState(false)
    const [amountToTopUp, setAmountToTopUp] = useState("")

    const [theme, setTheme] = useState(defaultTheme);
    const [logo, setLogo] = useState(depositsLogo);
    const [loading, setLoading] = useState(true)

    const [moveMoneyToFriendsState, setMoveMoneyToFriendsState] = useState(initailMoveMoneyConfig)
    const [topUpState, setTopUpState] = useState(topUpConfig)
    const [internationalTransfer, setInternationalTransfer] = useState(initialInternationalTransferConfig)
    const [windowSize, setWindowSize] = useState(null)
    const [splashed, setSplashed] = useState(false)

    // get the token manually from the url passed
    let userToken = getRouteTokenFromUrl(window.location.pathname) || 'deposits'


    const getThemeAndLogo = async (userToken) => {
        try {
            const response = await getThemeLogo(userToken)
            const { company_brand_color, logo } = response


            // set app logo
            setLogo(logo)

            // set app theme color

            // check to change white theme color to black for readability
            const fetchedThemeColor = (company_brand_color?.toLowerCase() === 'white'
                || company_brand_color?.toLowerCase() === '#ffffff')
                ? '#525964'
                : company_brand_color

            // get the text color to be used on the theme background
            const themedText = getTextColor(fetchedThemeColor)

            setTheme(prev => ({
                ...prev,
                themedText: themedText ?? 'black',
                accent: fetchedThemeColor ?? defaultTheme.accent,
                inActive: fetchedThemeColor ? `${fetchedThemeColor}65` : defaultTheme.primaryBlur,
                primaryBlur: fetchedThemeColor ? `${fetchedThemeColor}20` : defaultTheme.primaryBlur,
            }))

            setLoading(false)

        } catch (error) {
            // getThemeAndLogo('money')
            console.log(error);
        }
    }



    useEffect(() => {
        const formatWindow = () => {
            const windowInnerWidth = window.innerWidth;
            if (windowInnerWidth <= 450) {
                const remFontSize = (windowInnerWidth / 25.875)
                document.querySelector(":root").style.fontSize = `${remFontSize}px`;
            } else {
                document.querySelector(":root").style.fontSize = `10px`;
            }
        }
        window.addEventListener('resize', (e) => {
            formatWindow()
            setWindowSize(window.innerWidth)
        })
        formatWindow()
        setWindowSize(window.innerWidth)


        getThemeAndLogo(userToken)
    }, [])



    return (

        <GlobalContext.Provider value={
            {
                showSideMenu,
                setShowSideMenu,
                moveMoneyToFriendsState,
                setMoveMoneyToFriendsState,
                amountToTopUp,
                setAmountToTopUp,
                internationalTransfer,
                setInternationalTransfer,
                topUpState,
                setTopUpState,
                windowSize,
                theme,
                logo,
                userToken,
                splashed,
                setSplashed
            }
        }>
            <ThemeProvider theme={theme}>

                {!loading
                    ? <BrowserRouter>
                        <AppRoutes />
                    </BrowserRouter>
                    : <div style={loadingStyle}>
                        <Oval
                            height={100}
                            width={100}
                            color='#0DB9E9'
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor='#0DB9E9'
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    </div>

                }
            </ThemeProvider>
        </GlobalContext.Provider>
    )
};

export default App;


const loadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
}
import styled from 'styled-components'
// import { Icon } from "@deposits/ui-kit-react";
// import { Add, ChevronArrowRight } from "@deposits/ui-kit-react/dist/components/Icons";
import { SizedBox } from "../../../global/components/SizedBox";
import { Link } from "react-router-dom";
import { MoveDeposit, TopUp, SendPayRequest, SendIntl, PayFriends, TransferAccts, WireTransfer, Withdraw, BuyCrypto } from './icons'
import { useContext } from 'react'
import { GlobalContext } from '../../../../App';

const StyledObject = styled.div`
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding:  1.5rem;
    gap: 1.5rem;
    
    ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
    
    .sectionWrapper{
        .title{
            font-weight: 600;
            font-size: 1.625rem;
            line-height: 2rem;
            color: var(--color-label-400);
        }
        
        .navItems{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            
            *{
                text-decoration: none;
            }
            
            .navItem{
                display: flex;
                width: 100%;
                padding: 0.8rem 0 1.1rem;
                align-items: center;
                cursor: pointer;
                border-bottom: 1px solid var(--color-primary-bg);
                
          
                
                .descText{
                    font-size: 1rem;
                    line-height: 1.25rem;
                    flex-grow: 1;
                    text-decoration: none;
                    color: var(--color-label-300);
                    font-weight:600;
                }
            }
            
        }
    }
    
`;

const navConfig = [
    {
        title: "Add Money",
        links: [
            {
                Icon: MoveDeposit,
                label: "Move your direct Deposit",
            },
            {
                Icon: TransferAccts,
                label: "Transfer between accounts",
            },
            {
                Icon: TopUp,
                label: "Top up",
                to: "/topup/enteramount",
                highlighted: true
            },
            {
                Icon: WireTransfer,
                label: "Wire Transfer",
            },
        ]

    },
    {
        title: "Send & Request",
        links: [
            {
                Icon: PayFriends,
                label: "Pay or send to friends",
                to: "/movemoney/directdeposit",
                highlighted: true
            },
            {
                Icon: SendPayRequest,
                label: "Send payment request",
            },
            {
                Icon: Withdraw,
                label: "Withdraw to linked bank accounts",
            },
            {
                Icon: SendIntl,
                label: "Send Internationally",
                to: "/send_money/internationally/specify_amount",
                highlighted: true
            },
            {
                Icon: BuyCrypto,
                label: "Buy crypto",
                to: "/"
            },
        ]

    }
]


export const Body = () => {
    const { theme, userToken } = useContext(GlobalContext)
    const home = `/${userToken}`

    return (
        <StyledObject>
            {navConfig.map((sectionConfig) => {
                return (
                    <div className={"sectionWrapper"}>
                        <div className={"title"}>{sectionConfig.title}</div>
                        <SizedBox height={1} />
                        <div className={"navItems"}>
                            {sectionConfig.links.map(({ Icon, ...navItem }) => {
                                return (
                                    <Link to={navItem.to ? (home + navItem?.to) : "#"} onClick={(e) => {
                                        if (!navItem?.to) {
                                            e.preventDefault()
                                        }
                                    }}>
                                        <div className={`navItem ${navItem?.highlighted ? 'highlighted-border' : ''}`}>
                                            <Icon activeColor={theme?.accent} isActive={true} />

                                            <SizedBox width={1.078125} />
                                            <span className={`descText`}>{navItem.label}</span>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </StyledObject>
    )

}
import { useContext, useRef } from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Icon } from "@deposits/ui-kit-react";
import { ChevronArrowRightIcon, BriefcaseIcon, ProfileIcon } from "@deposits/ui-kit-react";
import { CircularButtonWithIcon } from "../../../global/components/buttons/CircularButtonWithIcon";
import { ListItemStyledComponent } from "../../../global/components/ListItemStyledComponent";
import { GlobalContext } from '../../../../App';


const StyledObject = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.4375rem;
  overflow-y: scroll;
  background-color: var(--color-white);

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .recipientItem {
    padding: 0.75rem 0;
    width: 100%;
    text-decoration: none;
  }
`;

export const Body = () => {
    const { userToken, theme } = useContext(GlobalContext)
    const home = `/${userToken}`

    const receivers = useRef([
        {
            icon: <CircularButtonWithIcon background_color={theme?.primaryBlur}
                size={3}>
                <Icon icon={ProfileIcon} smartColor={theme?.accent} />
            </CircularButtonWithIcon>,
            mainText: 'Myself',
            subText: 'Send money to myself',
            link: '/send_money/internationally/add_beneficiary'

        },
        {
            icon: <CircularButtonWithIcon background_color={theme?.primaryBlur}
                size={3}>
                <Icon icon={ProfileIcon} smartColor={theme?.accent} />
            </CircularButtonWithIcon>,
            mainText: 'Someone Else',
            subText: 'Send money to someone else',
            link: '/send_money/internationally/select_beneficiary'

        },
        {
            icon: <CircularButtonWithIcon background_color={theme?.primaryBlur}
                size={3}>
                <Icon icon={BriefcaseIcon} smartColor={theme?.accent} />
            </CircularButtonWithIcon>,
            mainText: 'Business / Organization',
            subText: 'Send money to business or organization'

        },
    ])



    return (
        <StyledObject>
            {receivers.current.map((item) => (
                <Link to={item.link ? (home + item.link) : '#'}
                    className={`recipientItem ${item.link ? 'highlighted-border' : ''}`}>
                    <ListItemStyledComponent contentSubText={item.subText}
                        contentMainText={item.mainText} logoElement={item.icon}
                        trailingIconElement={<Icon icon={ChevronArrowRightIcon}
                            smartColor={'var(--color-767E85)'} />} />
                </Link>
            ))
            }
        </StyledObject>
    )

}
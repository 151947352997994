import { useContext } from "react";
import { GlobalContext } from "../../../App";



const Splash = ({ className }) => {
  const { theme, logo } = useContext(GlobalContext);



  return (
    <div className={className} background={theme.accent}>

      <img src={logo} alt='company logo' />

    </div>
  )

}

export default Splash
import styled from 'styled-components';
import { Icon, TextField } from "@deposits/ui-kit-react";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useRef } from "react";
import { GlobalContext } from "../../../../App";
import { SendToNewRecipientButtonComponent } from "../../../global/components/SendToNewRecipientButtonComponent";
import { BankIcon, CloseIcon } from "@deposits/ui-kit-react";
import { ListItemStyledComponent } from "../../../global/components/ListItemStyledComponent";
import { CircularButtonWithIcon } from "../../../global/components/buttons/CircularButtonWithIcon";


const StyledObject = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1.4375rem 0;
  overflow-y: scroll;
  background-color: var(--color-white);

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .headerText {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    color: var(--color-tertiary-02);
    text-align: center;
  }

  .controlsWrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    * {
      text-decoration: none;
    }

    .navItemSendToRecipient {
      height: 5rem !important;
      padding: 1rem 0;
    }

    .navItem {
      width: 100%;
      cursor: pointer;
      border-bottom: 1px solid var(--color-primary-bg);
      height: 4.5625rem;
      padding: 0.75rem 0 0.875rem 0.25rem;


      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;

const beneficiariesList = [
    {
        name: 'John Alves',
        iban: "***3498"
    },
    {
        name: 'Sophia Alexander',
        iban: "***6291"
    },
    {
        name: 'Ella Ethan',
        iban: "***2952"
    },
    {
        name: 'Sophia Alexander',
        iban: "***6291"
    },
    {
        name: 'Ella Ethan',
        iban: "***2952"
    },
    {
        name: 'Sophia Alexander',
        iban: "***6291"
    }, {
        name: 'John Alves',
        iban: "***3498"
    },
    {
        name: 'Sophia Alexander',
        iban: "***6291"
    },
    {
        name: 'Ella Ethan',
        iban: "***2952"
    },
]
export const Body = () => {
    const randomPick = useRef(Math.floor(beneficiariesList.length * Math.random() * 0.5))
    const { internationalTransfer, setInternationalTransfer } = useContext(GlobalContext)
    const { theme, userToken } = useContext(GlobalContext)

    const navigate = useNavigate()

    const home = `/${userToken}`

    return (
        <StyledObject>
            <div className="headerText">
                To Someone else
            </div>
            <div className="controlsWrapper">
                <TextField
                    label={null}
                    placeholder="To Recipient name, Account number"
                    size={"small"}
                    inputClassName={'textInputField'}
                />
                <Link to={home + '/movemoney/towallet/newrecipient'}>
                    <div className={"navItem navItemSendToRecipient highlighted-border"}>
                        <SendToNewRecipientButtonComponent />
                    </div>
                </Link>
                {
                    beneficiariesList.map((config, index) => (
                        <Link to={home + '/send_money/internationally/review'} onClick={(e) => {
                            e.preventDefault()
                            const stateData = {
                                ...internationalTransfer,
                                recipientAccountName: config.name,
                                destinationIBANNumber: config.iban
                            }
                            setInternationalTransfer(stateData)
                            navigate(home + '/send_money/internationally/review')
                        }}>
                            <div
                                className={"navItem " + ((index === randomPick.current) ? "highlighted-border " : " ")}>
                                <ListItemStyledComponent contentSubText={`IBAN ending with ${config.iban.substring(3)}`}
                                    contentMainText={config.name}
                                    logoElement={<CircularButtonWithIcon
                                        background_color={theme?.primaryBlur}
                                        size={3}>
                                        <Icon icon={BankIcon}
                                            smartColor={theme?.accent} />
                                    </CircularButtonWithIcon>}
                                    trailingIconElement={<Icon icon={CloseIcon}
                                        smartColor={'var(--color-767E85)'} />} />
                            </div>
                        </Link>
                    )
                    )
                }
            </div>
        </StyledObject>
    )

}
export const formatAccNumber = (value) => {
    let prefix = ''
    let count = 0;
    while (count < value.length - 3) {
        prefix += "*"
        count++
    }
    return prefix + value.substring(value.length - 3)
}


export const formatIBANNumber = (value) => {
    let prefix = ''
    let count = 0;
    while (count < value.length - 4) {
        prefix += "*"
        count++
    }
    return prefix + value.substring(value.length - 4)
}

export const formatIBANGet4LengthEndingNumber = (value) => {
    return value.length > 4 ? value.substring(value.length - 4) : value.length
}
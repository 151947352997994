import styled from 'styled-components'
import { Icon } from "@deposits/ui-kit-react";
import { AddIcon, ChevronArrowRightIcon } from "@deposits/ui-kit-react";
import { SizedBox } from "../../../global/components/SizedBox";
import { Link } from "react-router-dom";
import { NumericalKeyBoardContainer } from "../../../global/components/numerical_keyboard/Container";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../App";
import { numberWithCommas } from '../../../../helpers/numberWithCommas';

const StyledObject = styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    
    .displayPalette{
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        
        
        .accountBalance{
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.218125rem;
            color: var(--color-label-200);
        }
        
        .typedInput{
            font-size: 4.5rem;
            font-weight: 600;
            text-align: center;
            color: var(--color-label-50);
            line-height: 4.75rem;
            width: 100%;
            border:none;
            outline: none;
            
            &.active{
                color: var(--color-label-400);
            }
        }
        
        *{
            text-decoration: none;
        }
        
        .buttonWrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            padding: 0 1.5rem;
            width: 100%;
            margin-top: auto;
            text-transform: capitalize;
            
            .buttonItem{
                color:  ${({ theme }) => theme?.themedText};
                background-color:  ${({ theme }) => theme?.inActive};
                font-weight:  600;
                font-size: 1rem;
                line-height: 1rem;
                cursor: pointer;
                border-radius: 0.375rem;
                width: 10.9375rem;
                height: 3.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 1.5rem;


                  &.active{
                      background-color: ${({ theme }) => theme?.accent};
                      font-size: 1rem;
                      line-height: 1rem;
                      font-weight: 600;
                  }
            }
        }
    }
`;

export const Body = () => {
    const {
        moveMoneyToFriendsState,
        setMoveMoneyToFriendsState,
        userToken
    } = useContext(GlobalContext);

    const home = `/${userToken}`


    const [randomNumberToHighlight, setRandomNumberToHighlight] = useState(null)

    useEffect(() => {
        if (!moveMoneyToFriendsState?.amountToSend || moveMoneyToFriendsState?.amountToSend?.length < 3) {
            setRandomNumberToHighlight(Math.ceil(Math.random() * 9))
        } else {
            setRandomNumberToHighlight(null)
        }
    }, [moveMoneyToFriendsState])

    const handleReceiveButtonClick = (e) => {
        let { value } = { ...e.target }
        value = value.split('')
            .filter(val => val !== ',' && val !== '$')
            .join('')

        if (value < 0 || value > 9999 || isNaN(Number(value))) return 0
        setMoveMoneyToFriendsState(prev => ({ ...prev, amountToSend: value }))
    }

    return (
        <StyledObject>
            <div className={"displayPalette"}>
                <div className={"accountBalance"}>
                    Account balance : $6,790
                </div>
                <SizedBox height={15.625} />

                <input className={"typedInput " + (moveMoneyToFriendsState.amountToSend ? "active" : "")}
                    placeholder={'$'}
                    onChange={handleReceiveButtonClick}
                    value={'$' + numberWithCommas(moveMoneyToFriendsState.amountToSend)}
                />


                <div className={"buttonWrapper"}>
                    <Link to={moveMoneyToFriendsState.amountToSend ? (home + '/movemoney/directdeposit/tobank') : "#"}>
                        <div

                            className={"buttonItem needActiveValidation " + (moveMoneyToFriendsState.amountToSend ? "active " : " ")}>
                            To friends
                        </div>
                    </Link>
                    <Link to={moveMoneyToFriendsState.amountToSend ? (home + '/movemoney/directdeposit/tobank') : "#"}>
                        <div
                            className={"buttonItem needActiveValidation " + (moveMoneyToFriendsState.amountToSend ? "active " : " ") + ((moveMoneyToFriendsState.amountToSend && moveMoneyToFriendsState.amountToSend?.length >= 2) ? "highlighted-border " : " ")}>
                            To bank
                        </div>
                    </Link>
                </div>
            </div>
            {/* <NumericalKeyBoardContainer callback={handleReceiveButtonClick} keyToHighlight={randomNumberToHighlight} /> */}
        </StyledObject>
    )

}
import styled from 'styled-components';
import { Switch, TextField } from "@deposits/ui-kit-react";
import { SizedBox } from "../../../global/components/SizedBox";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { faker } from '@faker-js/faker';
import { GlobalContext } from "../../../../App";


const StyledObject = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem 2.5rem;
  overflow-y: scroll;
  background-color: var(--color-white);

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .headerText {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    color:  ${({ theme }) => theme?.accent};
    text-align: center;
  }

  .controlsWrapper {
    margin-top: 1.1875rem;
    display: flex;
    flex-direction: column;

    .sectionHeading {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.25rem;
    }


    .choicePanel {
      display: flex;

      .choice {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1rem;
        color: var(--color-label-100);
        flex-grow: 1;
        cursor: pointer;

        &.active {
          color: ${({ theme }) => theme?.accent};
          border-bottom: 0.25rem solid  ${({ theme }) => theme?.accent};
        }

        &.not-active {
          cursor: not-allowed;
        }
      }
    }

    .addressDetailsWrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    * {
      text-decoration: none;
    }
  }


  .buttonItem {
    height: 3.5rem;
    background-color: ${({ theme }) => theme?.inActive};
    border-radius: 0.125rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height 1rem;
    color:  ${({ theme }) => theme?.themedText};
    font-weight: 600;
    margin-top: auto;
    text-decoration: none;

    &.active {
      background-color: ${({ theme }) => theme?.accent};
    }
  }
}
`;

const inputFields = ["email", "account_name", "account_number", "address", "suite", "zip_code", "city", "state", "description"]
const generatorMapping = {
    email: () => faker.internet.email(),
    account_name: () => [faker.name.firstName(), faker.name.lastName()].join(' '),
    account_number: () => faker.finance.account(8),
    address: () => faker.address.streetAddress(true),
    suite: () => faker.address.secondaryAddress(),
    zip_code: () => faker.address.zipCode('######'),
    city: () => faker.address.city(),
    state: () => faker.address.state(),
    description: () => faker.lorem.sentence()
}


export const Body = () => {
    const [canProceed, setCanProceed] = useState(false)
    const [data, setData] = useState({})
    const activeField = useRef(null)
    const navigate = useNavigate()
    const { internationalTransfer, setInternationalTransfer, userToken } = useContext(GlobalContext)

    const home = `/${userToken}`

    const handleOnChangeField = (e, field) => {
        // activeField.current = field;
        const tempData = { ...data }
        tempData[field] = e.target.value
        setData(tempData)
    }

    const handleOnClickProceed = () => {
        if (canProceed) {
            const stateData = {
                ...internationalTransfer,
                recipientAccountName: data.account_name,
                destinationIBANNumber: data.account_number
            }
            setInternationalTransfer(stateData)
            navigate(home + '/send_money/internationally/review')
        }
    }

    useEffect(() => {
        if (Object.keys(data).length) {
            const tempData = { ...data }
            let modifiedState = false;
            for (let field of inputFields) {
                if (field !== activeField.current && !data[field]) {
                    tempData[field] = generatorMapping[field]()
                    modifiedState = true;
                }
            }
            if (modifiedState) {
                setData(tempData)
            }
        }
    }, [data])


    useEffect(() => {
        if (data) {
            for (let item of inputFields) {
                if (!data[item]) {
                    setCanProceed(false)
                    return
                }
            }
            setCanProceed(true)
        } else {
            setCanProceed(false)
        }
    }, [data])
    return (
        <StyledObject>
            <div className="headerText">
                To Myself
            </div>
            <div className="controlsWrapper">
                <TextField
                    label={null}
                    placeholder="Email"
                    size={"small"}
                    inputClassName={'textInputField'}
                    value={data?.email}
                    onFocus={(e) => handleOnChangeField(e, 'email')}
                />
                <SizedBox height={1.5} />
                <div className="sectionHeading">Bank details</div>
                <SizedBox height={1} />
                <TextField
                    label={null}
                    placeholder="Account Name"
                    size={"small"}
                    inputClassName={'textInputField'}
                    value={data?.account_name}
                    onFocus={(e) => handleOnChangeField(e, 'account_name')}
                />
                <SizedBox height={1} />
                <TextField
                    label={null}
                    placeholder="Account Number"
                    size={"small"}
                    inputClassName={'textInputField'}
                    value={data?.account_number}
                    onFocus={(e) => handleOnChangeField(e, 'account_number')}
                />
                <SizedBox height={1.5} />
                <div className="sectionHeading">Address</div>
                <SizedBox height={1} />
                <div className="choicePanel">
                    <div className="choice active">
                        Inside Europe
                    </div>
                    <div className="choice not-active"
                    >
                        Outside Europe
                    </div>
                </div>
                <SizedBox height={1} />
                <div className='addressDetailsWrapper'>
                    <TextField
                        label={null}
                        placeholder="Address"
                        size={"small"}
                        inputClassName={'textInputField'}
                        value={data?.address}
                        onFocus={(e) => handleOnChangeField(e, 'address')}
                    />
                    <TextField
                        label={null}
                        placeholder="Suite"
                        size={"small"}
                        inputClassName={'textInputField'}
                        value={data?.suite}
                        onFocus={(e) => handleOnChangeField(e, 'suite')}
                    />
                    <TextField
                        label={null}
                        placeholder="Zip Code"
                        size={"small"}
                        inputClassName={'textInputField'}
                        value={data?.zip_code}
                        onFocus={(e) => handleOnChangeField(e, 'zip_code')}
                    />
                    <TextField
                        label={null}
                        placeholder="City"
                        size={"small"}
                        inputClassName={'textInputField'}
                        value={data?.city}
                        onFocus={(e) => handleOnChangeField(e, 'city')}
                    />
                    <TextField
                        label={null}
                        placeholder="State"
                        size={"small"}
                        inputClassName={'textInputField'}
                        value={data?.state}
                        onFocus={(e) => handleOnChangeField(e, 'state')}
                    />
                </div>
                <SizedBox height={1.5} />
                <div className="sectionHeading">Additional information</div>
                <SizedBox height={1} />
                <TextField
                    label={null}
                    placeholder="Description"
                    size={"small"}
                    inputClassName={'textInputField'}
                    value={data?.description}
                    onFocus={(e) => handleOnChangeField(e, 'description')}
                />
                <SizedBox height={1.5} />
                <Switch label={'Save beneficiary'} className={'switchInput'} colorScheme={'primary'} />
                <SizedBox height={3.25} />
                <Link to={home + '/send_money/internationally/review'} onClick={(e) => {
                    e.preventDefault()
                    handleOnClickProceed()
                }}
                    className={"buttonItem needActiveValidation " + (canProceed ? 'active' : '')}>
                    Continue
                </Link>
            </div>
        </StyledObject>
    )

}
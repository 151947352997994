import styled from 'styled-components';
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../../App";
import { faker } from '@faker-js/faker';
import { formatIBANNumber } from "../../../../helpers/formatters";
import { numberWithCommas } from '../../../../helpers/numberWithCommas';


const StyledObject = styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
    overflow-y: scroll;
    background-color: var(--color-white);
    
    ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
    }
    
    
    .contentListWrapper{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            
            .contentWrapper{
                display: flex;
                width: 100%;
                justify-content: space-between;
                padding-bottom: 1.375rem;
                border-bottom: 1px solid var(--color-label-25);
                
                .name{
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.25rem;
                    color: var(--color-label-300);
                }
                
                .value{
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1.25rem;
                    color: ${({ theme }) => theme?.accent};

                }
            }
        }

     .buttonItem{
        height: 3.5rem;
        background-color: ${({ theme }) => theme?.inActive};
        border-radius: 0.125rem;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        line-height 1rem;
        color: var(--color-gray-100);
        font-weight: 600;
        margin-top: auto;
        margin-bottom: 15px;
        text-decoration: none;
        color:  ${({ theme }) => theme?.themedText};

        &.active{
        background-color: ${({ theme }) => theme?.accent};
        }
    }

`;


export const Body = () => {
    const [moneyToReceive, setMoneyToReceive] = useState(null)
    const {
        internationalTransfer,
        userToken
    } = useContext(GlobalContext)
    const {
        fromCountryCurrencyCode,
        toCountryCurrencyCode,
        amountToTransfer,
        recipientAccountName,
        exchangeRate,
        destinationIBANNumber
    } = internationalTransfer;

    const home = `/${userToken}`

    const amountFixed = Math.floor((Math.random() * 2000))

    const fixedExchange = 0.95

    const config = [
        {
            title: "You send exactly",
            value: `${fromCountryCurrencyCode} ${numberWithCommas(
                Number(amountToTransfer || amountFixed)
            )}`
        },
        {
            title: "Recipient receives",
            value: `${toCountryCurrencyCode} ${numberWithCommas(
                Number(moneyToReceive
                    || (amountFixed * fixedExchange)).toFixed(2)
            )}`
        },
        {
            title: "Account holder",
            value: recipientAccountName || [faker.name.firstName(), faker.name.lastName()].join(' ')
        },
        {
            title: "IBAN",
            value: formatIBANNumber(destinationIBANNumber || faker.finance.account(8))
        },
        {
            title: "Estimated transfer arrival ",
            value: "30 hours"
        },
        {
            title: "Transfer fee",
            value: "USD 2.85"
        },
        {
            title: "Guaranteed rate",
            value: `1 ${fromCountryCurrencyCode} = ${exchangeRate.toFixed(3) || fixedExchange} ${toCountryCurrencyCode}`
        },
    ]


    useEffect(() => {
        if (amountToTransfer && exchangeRate) {
            setMoneyToReceive((Number.parseFloat(exchangeRate) * amountToTransfer).toFixed(2))
        }
    }, [amountToTransfer])
    return (
        <StyledObject>
            <div className="contentListWrapper">
                {
                    config.map((item) => (
                        <div key={item.title} className="contentWrapper">
                            <div className="name">{item.title}</div>
                            <div className="value">{item.value}</div>
                        </div>
                    ))
                }
            </div>
            <Link to={home + '/send_money/internationally/success'}
                className={"buttonItem active"}>
                Send Money
            </Link>
        </StyledObject>
    )

}
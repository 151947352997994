import styled from 'styled-components'
import { SizedBox } from "../../../global/components/SizedBox"
import { TextField, Switch } from "@deposits/ui-kit-react";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../App";
import { faker } from '@faker-js/faker';

const StyledObject = styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem 1.5rem 2.5rem; 
    background-color: var(--color-white);
    
    .messageBox{
        height: 4rem;
        background-color: var(--color-solid-1);
        text-align: center;
        padding: 0.75rem 0;
        color: var(--color-solid-2);
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 600;
    }
    
    .inputFieldsWrapper{
        display: flex;
        flex-direction: column;
        gap: 1.125rem;
    }
        
    .buttonItem{
        height: 3.5rem;
        background-color: ${({ theme }) => theme?.inActive};
        border-radius: 0.125rem;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        line-height 1rem;
        color: var(--color-gray-100);
        font-weight: 600;
        margin-top: auto;
        text-decoration: none;
        color: ${({ theme }) => theme?.themedColor};

        &.active{
        background-color: ${({ theme }) => theme?.accent};
        }
    }
    }
`;

const inputFields = [
    {
        field: "firstName",
        placeHolder: "First Name",
        generatorMethod: () => faker.name.firstName(),
    },
    {
        field: "lastName",
        placeHolder: "Last Name",
        generatorMethod: () => faker.name.lastName()
    },
    {
        field: "accountNumber",
        placeHolder: "Account Number",
        generatorMethod: () => faker.finance.account(8)

    },
    {
        field: "routingCode",
        placeHolder: "Routing Code",
        generatorMethod: () => faker.finance.routingNumber()
    },
    {
        field: "phoneNumberEmailAddress",
        placeHolder: "Phone Number or Email Address",
        generatorMethod: () => faker.internet.email()
    },
    {
        field: "note",
        placeHolder: "Add a note",
        generatorMethod: () => faker.lorem.sentence()
    }
]

export const Body = () => {
    const [canProceed, setCanProceed] = useState(false)
    const [data, setData] = useState({})
    const { moveMoneyToFriendsState, setMoveMoneyToFriendsState, userToken } = useContext(GlobalContext)
    const activeField = useRef(null)
    const navigate = useNavigate()

    const home = `/${userToken}`

    const handleOnChangeField = (e, field) => {
        const tempData = { ...data }
        tempData[field] = e.target.value
        setData(tempData)
    }

    const handleOnClickProceed = () => {
        if (canProceed) {
            const stateData = {
                ...moveMoneyToFriendsState,
                receivingRecipientName: `${data.firstName} ${data.lastName}`,
                receivingAccountNumber: data.accountNumber,
            }
            setMoveMoneyToFriendsState(stateData)
            navigate(home + '/movemoney/directdeposit/tobank/success')
        }
    }
    useEffect(() => {
        if (Object.keys(data).length) {
            const tempData = { ...data }
            let modifiedState = false;
            for (let item of inputFields) {
                if (item.field !== activeField.current && !data[item.field]) {
                    tempData[item.field] = item.generatorMethod()
                    modifiedState = true;
                }
            }
            if (modifiedState) {
                setData(tempData)
            }
        }
    }, [data])


    useEffect(() => {
        if (data) {
            for (let item of inputFields) {
                if (!data[item.field]) {
                    setCanProceed(false)
                    return
                }
            }
            setCanProceed(true)
        } else {
            setCanProceed(false)
        }
    }, [data])


    return (
        <StyledObject>
            <div className={"messageBox"}>
                <div>Recepient’s full name as seen on</div>
                <div>government issue ID is required</div>
            </div>
            <SizedBox height={1.5} />
            <div className="inputFieldsWrapper">
                {inputFields.map((item) => <TextField
                    key={item.field}
                    label={null}
                    placeholder={item.placeHolder}
                    size={"small"}
                    inputClassName={'textInputField'}
                    value={data[item.field]}
                    onFocus={(e) => {
                        handleOnChangeField(e, item.field)
                    }}
                />)}
            </div>
            <SizedBox height={1.5} />
            <Switch label={'Save beneficiary'} className={'switchInput'} colorScheme='primary' />
            <Link to={canProceed ? (home + '/movemoney/directdeposit/tobank/success') : '#'} onClick={(e) => {
                e.preventDefault()
                handleOnClickProceed()
            }}
                className={"buttonItem needActiveValidation " + (canProceed ? 'active' : '')}>
                Send Money
            </Link>
        </StyledObject>
    )

}
import styled from 'styled-components'
import zeroImage from './static/button_images/0.png'
import oneImage from './static/button_images/1.png'
import twoImage from './static/button_images/2.png'
import threeImage from './static/button_images/3.png'
import fourImage from './static/button_images/4.png'
import fiveImage from './static/button_images/5.png'
import sixImage from './static/button_images/6.png'
import sevenImage from './static/button_images/7.png'
import eightImage from './static/button_images/8.png'
import nineImage from './static/button_images/9.png'
import dotImage from './static/button_images/dot.png'
import clearImage from './static/button_images/clear.png'
import { SizedBox } from "../SizedBox";

const StyledContainer = styled.div`
    width: 100%;
    background-color: var(--color-button-background);
    padding: 1rem 0.5rem;
    
    .keyboardWrapper{
        display: grid;
        grid-template-areas: 
            "a b c"
            "d e f"
            "g h i"
            "j k l";
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 2rem);
        column-gap: 2px;
        row-gap: 12px;
        justify-items: center;
        align-items: center;
        
        .keyboard-button {
        font-size: 1.3rem;
        font-weight: 700;
        width: 82px;
        height: 2.8rem;
        
        
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    
    
    .footerWrapper{
        display: flex;
        justify-content: center;
        width: 100%;
        
        .draw{
            height: 4px;
            border-radius: 3px;
            width: 60%;
            background-color: var(--color-dark);
        }
    }
   
    
    
`;

const buttonConfig = [
    {
        label: "1",
        value: "1",
        area: "a",
        image: oneImage
    },
    {
        label: "2",
        value: "2",
        area: "b",
        image: twoImage
    },
    {
        label: "3",
        value: "3",
        area: "c",
        image: threeImage
    },
    {
        label: "4",
        value: "4",
        area: "d",
        image: fourImage
    },
    {
        label: "5",
        value: "5",
        area: "e",
        image: fiveImage
    },
    {
        label: "6",
        value: "6",
        area: "f",
        image: sixImage
    },
    {
        label: "7",
        value: "7",
        area: "g",
        image: sevenImage
    },
    {
        label: "8",
        value: "8",
        area: "h",
        image: eightImage
    },
    {
        label: "9",
        value: "9",
        area: "i",
        image: nineImage
    },
    {
        label: ".",
        value: ".",
        area: "j",
        image: dotImage
    },
    {
        label: "0",
        value: "9",
        area: "k",
        image: zeroImage
    },
    {
        label: "C",
        value: "clear",
        area: "l",
        image: clearImage
    },
]

export const NumericalKeyBoardContainer = ({ callback, keyToHighlight }) => {
    const registerButtonClick = (value) => {
        if (callback) {
            callback(value)
        }
    }
    return (
        <StyledContainer>
            <div className={"keyboardWrapper"}>
                {
                    buttonConfig.map((config) => {
                        return (
                            <div
                                className={`cursor-pointer keyboard-button ${(keyToHighlight && (keyToHighlight.toString() === config.value)) ? 'highlighted-border' : ''}`}
                                style={{
                                    gridArea: config.area
                                }} onClick={(e) => {
                                    registerButtonClick(config.value)
                                }}>
                                <img src={config.image} alt='numerical keys' />
                            </div>
                        )
                    })
                }
            </div>
            <SizedBox height={3} />
            <div className={"footerWrapper"}>
                <div className="draw"></div>
            </div>
        </StyledContainer>
    )

}
import styled from 'styled-components'
import {Body} from "./components/Body";
import {TopNav} from "../../global/components/TopNav";
import {MainWrapper} from "../../global/wrapper/MainWrapper";
import {SizedBox} from "../../global/components/SizedBox";
import {ChevronArrowLeftIcon} from '@deposits/ui-kit-react'
import {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../../App";
import {navCommands} from "../../../config/constants";


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: var(--color-white);
`;

export const SendMoneyInternationallySelectBeneficiaryPageContainer = () => {
    const [moneyToReceive, setMoneyToReceive] = useState(null)
    const {
        internationalTransfer
    } = useContext(GlobalContext)
    const {
        amountToTransfer,
        exchangeRate,
        toCountryCurrencyCode
    } = internationalTransfer;

    useEffect(() => {
        if (amountToTransfer && exchangeRate) {
            setMoneyToReceive((Number.parseFloat(exchangeRate) * amountToTransfer).toFixed(2))
        }
    }, [amountToTransfer])
    return (
        <MainWrapper>
            <>
                <StyledContainer>
                    <TopNav title={`Send ${toCountryCurrencyCode} ${moneyToReceive}`} buttonConfig={{
                        to: navCommands.GO_BACK,
                        icon: ChevronArrowLeftIcon
                    }}/>
                    <Body/>
                    <SizedBox height={3.125}/>
                </StyledContainer>
            </>
        </MainWrapper>
    )

}
import styled from 'styled-components'
import {TopNav} from "../../global/components/TopNav";
import {MainWrapper} from "../../global/wrapper/MainWrapper";
import {CloseIcon} from '@deposits/ui-kit-react'
import {SizedBox} from "../../global/components/SizedBox";
import {Body} from "./component/Body";


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: var(--color-white);
`;

export const MoveMoneyDirectDepositsToBankSuccessPageContainer = () => {
    return (
        <MainWrapper>
            <>
                <StyledContainer>
                    <TopNav title={""} buttonConfig={{
                        to: '/',
                        icon: CloseIcon
                    }}/>
                    <Body/>
                    <SizedBox height={2.5}/>
                </StyledContainer>
            </>
        </MainWrapper>
    )

}
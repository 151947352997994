import styled from 'styled-components';
import { SizedBox } from "../../../global/components/SizedBox";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CurrencySelectBox } from "../../../global/components/CurrencySelectBox";
import { TextField } from "@deposits/ui-kit-react";
import { getExchangeRate } from "../../../../helpers/api/currency";
import { GlobalContext } from "../../../../App";
import { Oval } from 'react-loader-spinner';
import { numberWithCommas } from '../../../../helpers/numberWithCommas';


const StyledObject = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem 3rem;
  overflow-y: scroll;
  background-color: var(--color-white);

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .accountBalance {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.218125rem;
    color: var(--color-label-200);
    text-align: center;
  }

  .amountSpecificationWrapper {
    .amountSpecification {
      margin-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--color-label-25);

      .purposeDescText {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5rem;
        color: var(--color-black);
      }

      .amountWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .currencySelectComponent {

        }

        .actualAmountComponent {
          margin: 0 !important;

          * {
            margin: 0 !important;
          }

          .textInputField {
            text-align: right;
            outline: none;
            border: none;
            font-size: 2rem !important;
            line-height: 2.5rem !important;
            font-weight: 700 !important;
            width: 12rem;
            color: var(--color-label-300);
          }

        }

        .loadingActualAmountComponent {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 12rem !important;
          height: 3.5rem;
          background-color: #f0f0f0;

        }

      }
    }
  }

  .extraDetails {
    .heading {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.25rem;
      color: var(--color-label-400);
    }

    .contentListWrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .contentWrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 1.375rem;
        border-bottom: 1px solid var(--color-label-25);

        .name {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.25rem;
          color: var(--color-label-300);
        }

        .value {
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.25rem;
          color: ${({ theme }) => theme?.secondary};

        }
      }
    }

  }


  .buttonItem {
    height: 3.5rem;
    background-color: ${({ theme }) => theme?.inActive};
    border-radius: 0.125rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height 1rem;
    color: var(--color-gray-100);
    font-weight: 600;
    margin-top: auto;
    text-decoration: none;
    color: ${({ theme }) => theme?.themedText};

    &.active {
      background-color: ${({ theme }) => theme?.accent};
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
`;

export const Body = () => {
  const [moneyToReceive, setMoneyToReceive] = useState(null)
  const [loading, setLoading] = useState(false)
  const [inputIsActive, setInputIsActive] = useState(false)
  const {
    internationalTransfer,
    setInternationalTransfer,
    theme,
    userToken
  } = useContext(GlobalContext)

  const body = `/${userToken}`

  const {
    fromCountryCode,
    fromCountryCurrencyCode,
    fromCountryCurrencySymbol,
    toCountryCode,
    toCountryCurrencyCode,
    toCountryCurrencySymbol,
    amountToTransfer,
    exchangeRate = 0.85
  } = internationalTransfer;

  const sendingCurrencyData = useRef({
    flag: `https://countryflagsapi.com/png/${fromCountryCode.toLowerCase()}`,
    currency_code: fromCountryCurrencyCode,
    currency_symbol: fromCountryCurrencySymbol
  })
  const receivingCurrencyData = useRef({
    flag: `https://countryflagsapi.com/png/${toCountryCode.toLowerCase()}`,
    currency_code: toCountryCurrencyCode,
    currency_symbol: toCountryCurrencySymbol
  })


  useEffect(() => {
    setLoading(true)
    if (toCountryCurrencyCode && fromCountryCurrencyCode) {
      const retrieveExchangeRate = async () => {
        const exchangeRate = (await getExchangeRate(toCountryCurrencyCode, fromCountryCurrencyCode))?.result || 0.85
        setInternationalTransfer(prev => ({ ...prev, exchangeRate }))
        setLoading(false)
      }
      retrieveExchangeRate()
    }
  }, [toCountryCurrencyCode, fromCountryCurrencyCode])

  useEffect(() => {
    if (amountToTransfer && exchangeRate) {
      setMoneyToReceive((Number.parseFloat(exchangeRate) * amountToTransfer).toFixed(2))
    }
  }, [amountToTransfer])


  return (
    <StyledObject>
      <div className={"accountBalance"}>
        Account balance : $6,790.37
      </div>
      <SizedBox height={1.25} />
      <div className="amountSpecificationWrapper">
        <div className="amountSpecification">
          <div className="purposeDescText">
            You send exactly
          </div>
          <SizedBox height={1} />
          <div className={'amountWrapper'}>
            <CurrencySelectBox className={'currencySelectComponent'} data={sendingCurrencyData.current} />
            <TextField
              key={'amount'}
              label={null}
              placeholder={''}
              size={"small"}
              className={`actualAmountComponent ${inputIsActive ? '' : 'highlighted-border'}`}
              inputClassName={'textInputField'}
              onBlur={e => {
                setInputIsActive(false)
                e.target.value = numberWithCommas(Number(internationalTransfer['amountToTransfer']).toFixed(2))
              }}
              onFocus={e => {
                setInputIsActive(true)
                e.target.value = numberWithCommas(internationalTransfer['amountToTransfer']) || 0
              }}

              onChange={(e) => {
                let { value } = { ...e.target }
                value = value.split('').filter(val => val !== ',').join('')
                // value = `${Number(value).toFixed(2)}`
                if (value < 0 || isNaN(Number(value))) return 0
                setInternationalTransfer(prev => ({ ...prev, amountToTransfer: value }))
              }}
              value={numberWithCommas(Number(internationalTransfer['amountToTransfer']))}
              type={'text'}
            />
          </div>
        </div>
        <div className="amountSpecification">
          <div className="purposeDescText">
            Recipient receives
          </div>
          <SizedBox height={1} />
          <div className={'amountWrapper'}>
            <CurrencySelectBox className={'currencySelectComponent'} data={receivingCurrencyData.current} />
            {loading ?
              <div className='loadingActualAmountComponent'>
                <Oval
                  height={20}
                  width={20}
                  color={theme?.accent}
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor={theme?.accent}
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
              :
              <TextField
                key={'amount'}
                label={null}
                placeholder={''}
                size={"small"}
                className={'actualAmountComponent'}
                inputClassName={'textInputField'}
                type={'text'}
                value={numberWithCommas(moneyToReceive)}
                disabled
              />
            }
          </div>
        </div>
      </div>
      <SizedBox height={2.5} />
      <div className="extraDetails">
        <div className="heading">Estimate and Fee Breakdown</div>
        <SizedBox height={2} />
        <div className="contentListWrapper">
          <div className="contentWrapper">
            <div className="name">Estimated transfer arrival</div>
            <div className="value">30 hours</div>
          </div>
          <div className="contentWrapper">
            <div className="name">Transfer fee</div>
            <div className="value">$2.85</div>
          </div>
          <div className="contentWrapper">
            <div className="name">Guaranteed rate</div>
            <div className="value">1 USD = {exchangeRate?.toFixed(3) || '0.85'} EUR</div>
          </div>
        </div>
      </div>
      <Link to={body + '/send_money/internationally/select_receiver'}
        className={"buttonItem needActiveValidation " + (amountToTransfer && moneyToReceive ? 'active' : '')}>
        Send Money
      </Link>
    </StyledObject>
  )

}
import styled from 'styled-components';
import { TextField } from "@deposits/ui-kit-react";
import { Link, useNavigate } from "react-router-dom";
import boaLogo from '../../../../static/logos/banks/boa.png';
import citiLogo from '../../../../static/logos/banks/citi.png';
import wellsFargoLogo from '../../../../static/logos/banks/wells-fargo.png';
import capitalOneLogo from '../../../../static/logos/banks/capital_one.png';
import americanExpressLogo from '../../../../static/logos/banks/american_express.png';
import { useContext, useRef } from "react";
import { GlobalContext } from "../../../../App";
import { ListItemStyledComponent } from "../../../global/components/ListItemStyledComponent";
import { SendToNewRecipientButtonComponent } from "../../../global/components/SendToNewRecipientButtonComponent";
import { LazyImageComponent } from "../../../global/components/LazyImageComponent";


const StyledObject = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.4375rem 0;
  overflow-y: scroll;
  background-color: var(--color-white);

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .controlsWrapper {
    display: flex;
    flex-direction: column;

    * {
      text-decoration: none;
    }

    .navItemSendToRecipient {
      height: 5rem !important;
      padding: 1rem 0;
    }

    .navItem {
      width: 100%;
      cursor: pointer;
      border-bottom: 1px solid var(--color-primary-bg);
      height: 4.5625rem;
      padding: 0.75rem 0 0.875rem 0.25rem;


      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding:0 !important;
        border-radius: 50%;
      }
    }
  }
`;

const recipientsList = [
    {
        "bankLogo": boaLogo,
        "accountName": "John Alves",
        "bankName": "Bank of America",
    },
    {
        "bankLogo": wellsFargoLogo,
        "accountName": "Sophia Alexander",
        "bankName": "Wells Fargo",
    },
    {
        "bankLogo": citiLogo,
        "accountName": "Ella Ethan",
        "bankName": "Citibank",
    },
    {
        "bankLogo": capitalOneLogo,
        "accountName": "Elijah Liam",
        "bankName": "Capital One",
    },
    {
        "bankLogo": citiLogo,
        "accountName": "Landon Tyler",
        "bankName": "Citibank",
    },
    {
        "bankLogo": citiLogo,
        "accountName": "Ella Ethan",
        "bankName": "Citibank",
    },
    {
        "bankLogo": americanExpressLogo,
        "accountName": "Elijah Liam",
        "bankName": "American Express",
    },
    {
        "bankLogo": boaLogo,
        "accountName": "John Alves",
        "bankName": "Bank of America",
    },
]
export const Body = () => {
    const {
        moveMoneyToFriendsState,
        setMoveMoneyToFriendsState,
        userToken
    } = useContext(GlobalContext);

    const home = `/${userToken}`

    const navigate = useNavigate()

    const randomPick = useRef(Math.floor(recipientsList.length * Math.random() * 0.5))
    const handleClickOnProceed = (data) => {
        setMoveMoneyToFriendsState({
            ...moveMoneyToFriendsState,
            receivingBankName: data?.bankName,
            receivingRecipientName: data?.accountName
        })
        navigate(home + '/movemoney/directdeposit/tobank/success')
    }
    return (
        <StyledObject>
            <TextField
                label={null}
                placeholder="To Recipient name, Account number"
                size={"small"}
                inputClassName={'textInputField'}
            />
            <div className="controlsWrapper">
                <Link to={home + '/movemoney/towallet/newrecipient'}>
                    <div className={"navItem navItemSendToRecipient highlighted-border"}>
                        <SendToNewRecipientButtonComponent />
                    </div>
                </Link>
                {
                    recipientsList.map((config, index) => (
                        <Link to={home + '/movemoney/directdeposit/tobank/success'} onClick={(e) => {
                            e.preventDefault()
                            handleClickOnProceed(config)
                        }}>
                            <div
                                className={"navItem " + ((index === randomPick.current) ? "highlighted-border " : " ")}>
                                <ListItemStyledComponent contentSubText={config.bankName}
                                    contentMainText={config.accountName} logoElement={<LazyImageComponent src={config.bankLogo} />} />
                            </div>
                        </Link>
                    )
                    )
                }
            </div>
        </StyledObject>
    )

}
import styled from 'styled-components';
import {Icon} from "@deposits/ui-kit-react";
import {ArrowUpRightIcon} from "@deposits/ui-kit-react";
import {CircularButtonWithIcon} from "./buttons/CircularButtonWithIcon";
import {useContext} from "react";
import {GlobalContext} from "../../../App";

const StyledComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 1.3125rem;
  width: 100%;


  .logoWrapper {
    width: 3rem;
    height: 3rem;
  }

  .contentWrapper {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: var(--color-label-400);
  }



`

export const SendToNewRecipientButtonComponent = () => {
    return (
        <StyledComponent>
            <div className="logoWrapper">
                <CircularButtonWithIcon background_color={'var(--color-4C97FA)'} size={3}>
                    <Icon icon={ArrowUpRightIcon} smartColor={'var(--color-white)'}/>
                </CircularButtonWithIcon>
            </div>
            <div className="contentWrapper">
                Send to new recipient
            </div>
        </StyledComponent>
    )

}
import { Route, Routes, useParams, Navigate } from "react-router-dom";
import { HomePageContainer } from "../components/home_page/Container";
import { MoveMoneyMainPageContainer } from "../components/move_money_pages/main/Container";
import { MoveMoneyDirectDepositsPageContainer } from "../components/move_money_pages/direct_deposits/Container";
import { MoveMoneyDirectDepositsToBankPageContainer } from "../components/move_money_pages/direct_deposits_to_bank/Container";
import { MoveMoneyDirectDepositsToBankSuccessPageContainer } from "../components/move_money_pages/direct_deposits_to_bank_success/Container";
import { SendMoneyToWalletNewRecipientContainer } from "../components/move_money_pages/send_money_to_wallet_new_recipient/Container";
import { TopUpEnterAmountPageContainer } from "../components/top_up_pages/top_up_enter_amount/Container";
import { TopUpSelectCardPageContainer } from "../components/top_up_pages/top_up_select_card/Container";
import { TopUpAddCardContainer } from "../components/top_up_pages/top_up_add_new_card/Container";
import { SendMoneyInternationallySpecifyAmountContainer } from "../components/send_internationally_pages/send_internationally_specify_amount_page/Container";
import { SendMoneyInternationallySelectReceiverPageContainer } from "../components/send_internationally_pages/send_internationally_select_receiver/Container";
import { SendMoneyInternationallySelectBeneficiaryPageContainer } from "../components/send_internationally_pages/send_internationally_select_existing_beneficiary/Container";
import { SendMoneyInternationallyAddBeneficiaryPageContainer } from "../components/send_internationally_pages/send_internationally_add_beneficiary/Container";
import { SendMoneyInternationallyReviewExchangePageContainer } from "../components/send_internationally_pages/send_internationally_review_exchange/Container";
import { SendMoneyInternationallySuccessPageContainer } from "../components/send_internationally_pages/send_internationally_success/Container";
import { TopUpSuccessPageContainer } from "../components/top_up_pages/top_up_success/Container";
import NotFound from "../components/404";


export const AppRoutes = () => {

       return (
              <Routes>
                     <Route path="/" exact element={<HomePageContainer />} />
                     <Route path="/:userToken" exact element={<HomePageContainer />} />
                     <Route path="/:userToken/movemoney" exact element={<MoveMoneyMainPageContainer />} />
                     <Route path="/:userToken/movemoney/directdeposit" exact element={<MoveMoneyDirectDepositsPageContainer />} />
                     <Route path="/:userToken/movemoney/directdeposit/tobank" exact
                            element={<MoveMoneyDirectDepositsToBankPageContainer />} />
                     <Route path="/:userToken/movemoney/directdeposit/tobank/success" exact
                            element={<MoveMoneyDirectDepositsToBankSuccessPageContainer />} />
                     <Route path="/:userToken/movemoney/towallet/newrecipient" exact
                            element={<SendMoneyToWalletNewRecipientContainer />} />
                     <Route path="/:userToken/topup/enteramount" exact
                            element={<TopUpEnterAmountPageContainer />} />
                     <Route path="/:userToken/topup/selectcard" exact
                            element={<TopUpSelectCardPageContainer />} />
                     <Route path="/:userToken/topup/addcard" exact
                            element={<TopUpAddCardContainer />} />
                     <Route path="/:userToken/topup/success" exact
                            element={<TopUpSuccessPageContainer />} />
                     <Route path="/:userToken/send_money/internationally/specify_amount" exact
                            element={<SendMoneyInternationallySpecifyAmountContainer />} />
                     <Route path="/:userToken/send_money/internationally/select_receiver" exact
                            element={<SendMoneyInternationallySelectReceiverPageContainer />} />
                     <Route path="/:userToken/send_money/internationally/select_beneficiary" exact
                            element={<SendMoneyInternationallySelectBeneficiaryPageContainer />} />
                     <Route path="/:userToken/send_money/internationally/add_beneficiary" exact
                            element={<SendMoneyInternationallyAddBeneficiaryPageContainer />} />
                     <Route path="/:userToken/send_money/internationally/review" exact
                            element={<SendMoneyInternationallyReviewExchangePageContainer />} />
                     <Route path="/:userToken/send_money/internationally/success" exact
                            element={<SendMoneyInternationallySuccessPageContainer />} />

                     <Route path="/*" exact element={<NotFound />} />
              </Routes>
       )
}
import styled from 'styled-components'
import { TextField, CardInputField } from "@deposits/ui-kit-react";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../App";
import { faker } from '@faker-js/faker';


const StyledObject = styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem 1.5rem 0.5rem; 
    background-color: var(--color-white);
    
    .messageBox{
        height: 4rem;
        background-color: var(--color-solid-1);
        text-align: center;
        padding: 0.75rem 0;
        color: var(--color-solid-2);
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 600;
    }
    
    .inputFieldsWrapper{
        display: flex;
        flex-direction: column;
        gap: 1.125rem;
        
        .joinedField{
            display: flex;
            gap: 1rem;
        }
    }
        
    .buttonItem{
        height: 3.5rem;
        background-color: var(--color-primary-200);
        border-radius: 0.125rem;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        line-height 1rem;
        color: var(--color-gray-100);
        font-weight: 600;
        margin-top: auto;
        margin-bottom: 2rem;
        text-decoration: none;
        background-color: ${({ theme }) => theme?.inActive};
        
        &.active{
        background-color: ${({ theme }) => theme?.accent};
        color: ${({ theme }) => theme?.themedText};
        }
    }
    }
`;

const inputFields = [
    {
        field: "creditCardNumber",
        placeHolder: "Credit Card Number",
        generatorFunction: () => faker.finance.creditCardNumber()
    },
    {
        field: "cardHolderName",
        placeHolder: "Card Holder Name",
        generatorFunction: () => [faker.name.firstName(), faker.name.lastName()].join(' ')
    },
    {
        joined: true,
        fields: [
            {
                field: "expiryDate",
                placeHolder: "MM/YYYY",
                generatorFunction: () => '08/2026'
            },
            {
                field: "cvvCode",
                placeHolder: "CVV Code",
                generatorFunction: () => faker.finance.creditCardCVV()
            },
        ]
    },
    {
        field: "zipCode",
        placeHolder: "Zip Code",
        generatorFunction: () => faker.address.zipCode('#####')
    }
]

export const Body = () => {
    const [canProceed, setCanProceed] = useState(false)
    const [data, setData] = useState({})
    const { topUpState, setTopUpState, userToken } = useContext(GlobalContext)
    const navigate = useNavigate()
    const activeField = useRef(null)

    const home = `/${userToken}`

    const handleOnChangeField = (e, field) => {
        // activeField.current = field;
        const tempData = { ...data }
        tempData[field] = e.target.value
        setData(tempData)
    }

    const handleOnClickProceed = () => {
        if (canProceed) {
            const stateData = {
                ...topUpState,
                accountName: data.cardHolderName
            }
            setTopUpState(stateData)
            navigate(home + '/topup/success')
        }
    }

    useEffect(() => {
        if (Object.keys(data).length) {
            const tempData = { ...data }
            let modifiedState = false;
            for (let item of inputFields) {
                if (item?.joined) {
                    for (let item_ of item?.fields) {
                        if (activeField.current !== item_.field && !data[item_.field]) {
                            tempData[item_.field] = item_.generatorFunction()
                            modifiedState = true;
                        }
                    }
                } else {
                    if (activeField.current !== item.field && !data[item.field]) {
                        tempData[item.field] = item.generatorFunction()
                        modifiedState = true;
                    }
                }
            }
            if (modifiedState) {
                setData(tempData)
            }
        }
    }
        ,
        [data]
    )

    useEffect(() => {
        if (data) {
            for (let item of inputFields) {
                if (item?.joined) {
                    for (let item_ of item?.fields) {
                        if (!data[item_.field]) {
                            setCanProceed(false)
                            return
                        }
                    }
                } else {
                    if (!data[item.field]) {
                        setCanProceed(false)
                        return
                    }
                }
            }
            setCanProceed(true)
        } else {
            setCanProceed(false)
        }
    }, [data])


    return (
        <StyledObject>
            <div className="inputFieldsWrapper">
                {inputFields.map((item) => {
                    if (item?.joined) {
                        return (
                            <div className="joinedField">
                                {
                                    item.fields.map((item_) => <TextField
                                        key={item_.field}
                                        label={null}
                                        placeholder={item_.placeHolder}
                                        size={"small"}
                                        inputClassName={'textInputField'}
                                        value={data[item_.field]}
                                        onFocus={(e) => handleOnChangeField(e, item_.field)}
                                    />)}
                            </div>
                        )
                    }

                    return (<TextField
                        key={item.field}
                        label={null}
                        placeholder={item.placeHolder}
                        size={"small"}
                        inputClassName={'textInputField'}
                        value={data[item.field]}
                        onFocus={(e) => handleOnChangeField(e, item.field)}
                    />
                    )
                })}
            </div>
            <Link to={home + '/topup/success'} onClick={(e) => {
                e.preventDefault()
                handleOnClickProceed()
            }}
                className={"buttonItem needActiveValidation " + (canProceed ? 'active' : '')}>
                Add Funds
            </Link>
        </StyledObject>
    )

}

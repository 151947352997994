import styled from 'styled-components';
import card1 from '../static/Mask-Group.png';
import card2 from '../static/Mask-Group-1.png';
import card3 from '../static/Mask-Group-1.png';
import { SizedBox } from "../../../global/components/SizedBox";
import { Radio } from "@deposits/ui-kit-react";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from '../../../../App';


const StyledObject = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem 3rem;
  overflow-y: scroll;
  background-color: var(--color-white);

  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }

  .controlsWrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;

    .buttonItem{
      height: 2.5rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.25rem;
      color: var(--color-gray-700);

      &.active{
        background-color: var(--color-gray-100);
      }
    }
  }

  .cardsWrapper{
    .cardItem{
      height: 4.5rem;
      padding: 0.8125rem 0.75rem 0.6875rem;
      display: flex;
      align-items: center;
      gap: 1.125rem;

      .imageWrapper{
        display: flex;
        height: 41px;
        align-items: center;
        padding: 18px 0;

        .img img{
          height: 31px;
          object-fit: cover ;
        }
        .card-details{
          margin: 0 0 3px 4px;
        }

        .card-details__type{
          color: var(--color-gray-700);
          padding: 2px;
        }

        .card-details__expiry{
          color: var(--color-label);
          padding: 2px;
        }
      }
    }
  }

  .buttonCardsWrapper{
    margin-top: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    a{
      text-decoration: none;
    }

    .buttonItemCard{
      font-weight:  600;
      font-size: 1rem;
      line-height: 1rem;
      cursor: pointer;
      border-radius: 0.375rem;
      height: 3.5rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .buttonItemAddCard{
      color: ${({ theme }) => theme?.accent};
      border: solid 1px ${({ theme }) => theme?.accent};
    }

    .buttonItemProceed{
      color: ${({ theme }) => theme?.themedText};
      background-color: ${({ theme }) => theme?.inActive};

      &.active{
      background-color: ${({ theme }) => theme?.accent};
      }
    }
  }
`;

const cardsList = [
  {
    "image": card1,
    "value": "1",
    "cardDetails": {
      type: 'Visa *3516',
      expiry: 'Expires 03/23'
    }
  },
  {
    "image": card2,
    "value": "2",
    "cardDetails": {
      type: 'Visa *9856',
      expiry: 'Expires 02/25'
    },
  },
  {
    "image": card3,
    "value": "3",
    "cardDetails": {
      type: 'Visa *8047',
      expiry: 'Expires 09/24'
    },
  },
]
export const Body = () => {
  const [selectedCard, setSelectedCard] = useState(null)


  const { userToken } = useContext(GlobalContext);
  const home = `/${userToken}`

  return (
    <StyledObject>
      <div className="controlsWrapper">
        <div className="buttonItem active">
          Cards
        </div>
        <div className="buttonItem">
          Banks
        </div>
      </div>
      <SizedBox height={1} />
      <div className="cardsWrapper">
        {
          cardsList.map((config, index) => (
            <div className={"cardItem"}>
              <Radio ringed={true} name={"card"} value={config.value}
                checked={selectedCard === config.value} onChange={(e) => {
                  setSelectedCard(e.target.value)
                }} />
              <div className="imageWrapper">
                <div className='img'>
                  <img src={config.image} alt='card' />
                </div>
                <div className='card-details'>
                  <p className='card-details__type'>{config.cardDetails.type}</p>
                  <p className='card-details__expiry'>{config.cardDetails.expiry}</p>
                </div>
              </div>
            </div>
          ))
        }
      </div>
      <div className="buttonCardsWrapper">
        <Link to={home + '/topup/addcard'} className="buttonItemCard buttonItemAddCard">
          Add new card
        </Link>
        <Link to={home + '/topup/enteramount'} className={("buttonItemCard buttonItemProceed ") + (selectedCard && 'active')} >
          Proceed
        </Link>
      </div>
    </StyledObject>
  )

}
import { MainWrapper } from "../global/wrapper/MainWrapper";
import styled from 'styled-components'
import { Hero } from "./components/Hero";
import { Body } from "./components/Body";
import { Footer } from "../global/components/BottomNav";
import { useContext, useEffect, useState } from "react";
import { GlobalContext, initailMoveMoneyConfig, initialInternationalTransferConfig } from "../../App";
import Splash from "./components/Splash";

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    
    .splash{
        background-color: white;
        // background-color: ${({ theme }) => theme?.inActive};
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 10;
        opacity: 0;
        transition: opacity 0.4s ease-in 2s;
    }
    
    .show-splash{
        opacity: 1;
    }

    .tangible{
        display: none;
    }

    && img{
        width: 200px;
        
    }
`;

export const HomePageContainer = () => {
    const { setInternationalTransfer, setMoveMoneyToFriendsState, splashed, setSplashed } = useContext(GlobalContext)
    const [splash, setSplash] = useState(true)
    const [tangible, setTangible] = useState(false)

    useEffect(() => {
        setInternationalTransfer(initialInternationalTransferConfig)
        setMoveMoneyToFriendsState(initailMoveMoneyConfig)

        if(splashed) {
            setSplash(false)
            setTangible(true)
        } else {
            setSplash(false)
            const splashTimer = setTimeout(() => {
                setTangible(true)
                setSplashed(true)
            }, 2500);
        }


        // return clearTimeout(splashTimer)
    }, [])

    return (
        <MainWrapper>
            <>
                <StyledContainer>

                    <Splash className={`splash  ${splash ? 'show-splash' : 'hide-splash'}  ${tangible ? 'tangible' : ''}`} />
                    <Hero />
                    <Body />
                    <Footer />
                </StyledContainer>
            </>
        </MainWrapper>
    )

}
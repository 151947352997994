import styled from 'styled-components'
import { Link } from "react-router-dom";
import moment from 'moment';
import { useContext } from "react";
import { GlobalContext } from "../../../../App";
import { SizedBox } from "../../../global/components/SizedBox";
import { formatIBANGet4LengthEndingNumber } from "../../../../helpers/formatters";
import faker from '@faker-js/faker';
import { numberWithCommas } from '../../../../helpers/numberWithCommas';


const StyledObject = styled.div`
    flex-grow: 1;
    width: 100%;
    padding: 0 1.4rem; 
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    align-items: center;
    
    .recipientDetails{
        display: flex;
        flex-direction: column;
        align-items:center;
        gap: 0;
        
        .successText{
            font-weight: 600;
            font-size: 1.125rem;
            line-height:1.4375rem;
            color: var(--color-label-400);
        }
        
        .otherDesc{
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 400;
            color: var(--color-label-200);
            
        }
    }
    
    .amountSent{
        color: var(--color-green-600);
        font-size: 3rem;
        line-height: 3.25rem;
        font-weight: 600;
    }
    
    .buttonWrapper{
        margin-top: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        
        
        *{
            text-decoration: none;
        }
        
        .buttonItem{
            height: 3.5rem;
            background-color: var(--color-gray-100);
            border-radius: 0.125rem;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            line-height 1rem;
            color: var(--color-gray-700);
            font-weight: 600;
        }
        
        .buttonColored{
            background-color: ${({ theme }) => theme?.accent};
            color: ${({ theme }) => theme?.themedText};

        }
    }
`;

export const Body = () => {
    const {
        internationalTransfer,
        userToken
    } = useContext(GlobalContext)
    const {
        recipientAccountName,
        destinationIBANNumber,
        fromCountryCurrencySymbol,
        amountToTransfer
    } = internationalTransfer;

    const home = `/${userToken}`

    const amountFixed = Math.floor((Math.random() * 800))

    const data = {
        recipientName: recipientAccountName || [faker.name.firstName(), faker.name.lastName()].join(' '),
        IBANEnding: formatIBANGet4LengthEndingNumber(destinationIBANNumber || faker.finance.account(8)),
        amount: amountToTransfer || amountFixed,
        currencySymbol: fromCountryCurrencySymbol

    }


    return (
        <StyledObject>
            <SizedBox height={9} />
            <div className={"recipientDetails"}>
                <span className="successText">Payment Successful</span>
                <SizedBox height={0.5} />
                <span className="otherDesc">Sent To {data.recipientName}, IBAN</span>
                <span
                    className="otherDesc">ending with {data.IBANEnding}</span>
                <span className="otherDesc">Today {moment().format('h:mma')}</span>
            </div>
            <SizedBox height={6.5625} />
            <span className="amountSent">{data.currencySymbol}{numberWithCommas(data.amount)}.00</span>
            <div className="buttonWrapper">
                <Link to={home} className="buttonItem buttonColored highlighted-border">
                    Done
                </Link>
                <Link to={home} className="buttonItem">
                    Share Receipt
                </Link>
                <Link to={home + '/movemoney'} className="buttonItem">
                    Send Another Payment
                </Link>
            </div>
        </StyledObject>
    )
}
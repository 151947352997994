import styled from 'styled-components';
import {ChevronArrowDownIcon} from '@deposits/ui-kit-react'
import {Icon} from "@deposits/ui-kit-react";


const StyledComponent = styled.div`
    background-color: var(--color-label-25);
    padding: 0.75rem 1rem;
    width: 8.9375rem;
    
    .mainContentWrapper{
        display: flex;
        gap: 0.333125rem;
        width: 100%;
        align-items: center;
        position: relative;
       
        
        
        .countryFlag{
            width: 1.666875rem;
            height: 1rem;
            
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            
        }
        
        
        .currency{
            font-size: 1rem;
            line-height: 1.5rem;
            flex-grow: 1;
            .name{
                font-weight: 600;
            }
            
            .symbol{
                font-weight: 400;
            }
        }
        
        
        .downArrow{
            *{
               
            height: 1.750rem !important;
            width: 1.25rem !important;
            color: var(--color-767E85) !important;
            }
        }
    }
    
    
`


export const CurrencySelectBox = ({className, data, onSelectCallback}) => {
    return (
        <StyledComponent className={className}>
            <div className='mainContentWrapper'>
                <div className='countryFlag'>
                    <img src={data?.flag} alt={''}/>
                </div>
                <div className={'currency'}>
                    <span className='name'>{data?.currency_code}</span>
                    <span> </span>
                    <span className="symbol" dangerouslySetInnerHTML={{__html: data?.currency_symbol}}></span>
                </div>
                <div className={'downArrow'}>
                    <Icon icon={ChevronArrowDownIcon} smartColor={'var(--color-767E85)'} className="cursor-pointer"/>
                </div>
            </div>
        </StyledComponent>
    )

}

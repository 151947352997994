import styled from 'styled-components'
import { Body } from "./components/Body";
import { TopNav } from "../../global/components/TopNav";
import { MainWrapper } from "../../global/wrapper/MainWrapper";
import { SizedBox } from "../../global/components/SizedBox";
import { ChevronArrowLeftIcon } from '@deposits/ui-kit-react'
import { navCommands } from "../../../config/constants";


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
`;

export const TopUpEnterAmountPageContainer = () => {
    return (
        <MainWrapper>
            <>
                <StyledContainer>
                    <TopNav title={"Top up"} buttonConfig={{
                        to: navCommands.GO_BACK,
                        icon: ChevronArrowLeftIcon
                    }} />
                    <Body />
                </StyledContainer>
            </>
        </MainWrapper>
    )

}
import styled from 'styled-components'
import { Body } from "./components/Body";
import { TopNav } from "../../global/components/TopNav";
import { MainWrapper } from "../../global/wrapper/MainWrapper";
import {ChevronArrowLeftIcon} from "@deposits/ui-kit-react";
import { useContext } from "react";
import { GlobalContext } from "../../../App";
import { NumericalKeyBoardContainer } from "../../global/components/numerical_keyboard/Container";
import { navCommands } from "../../../config/constants";


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: var(--color-white);
`;

export const TopUpAddCardContainer = () => {
    const { topUpState } = useContext(GlobalContext)
    return (
        <MainWrapper>
            <>
                <StyledContainer>
                    <TopNav title={`Top Up $${topUpState?.amountToTopUp || ''}`} buttonConfig={{
                        to: navCommands.GO_BACK,
                        icon: ChevronArrowLeftIcon
                    }} />
                    <Body />
                   {/* <NumericalKeyBoardContainer /> */}
                </StyledContainer>
            </>
        </MainWrapper>
    )

}

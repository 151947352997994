import styled from 'styled-components'
import { Icon } from "@deposits/ui-kit-react";

import { MenuIcon } from '@deposits/ui-kit-react'
import { useContext } from "react";
import { SizedBox } from "./SizedBox";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../App";
import { SideMenuContainer } from "./side_menu/Container";
import { navCommands } from "../../../config/constants";

const StyledObject = styled.div`
    background-color: var(--color-white);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.5rem 1.875rem 1.5rem 1.525rem;
    
    
    .titleText{
        font-size: 1.125rem;
        font-weight: 600;
        color: var(--color-label-400);
        line-height: 1.4375rem;
    }
    
`;


export const TopNav = ({ title, buttonConfig }) => {
    const { setShowSideMenu, showSideMenu, theme } = useContext(GlobalContext);
    const navigate = useNavigate()
    const handleNavCommand = (command) => {
        if (command === navCommands.GO_BACK) {
            navigate(-1)
        }
    }
    return (
        <StyledObject>
            {buttonConfig ? (Object.values(navCommands).includes(buttonConfig.to) ?
                <div onClick={(e) => {
                    handleNavCommand(buttonConfig.to)
                }}>
                    <Icon icon={buttonConfig.icon} smartColor={theme?.accent} className="cursor-pointer" />
                </div> :
                <Link to={buttonConfig.to}>
                    <Icon icon={buttonConfig.icon} smartColor={theme?.accent} className="cursor-pointer" />
                </Link>) :
                <Icon height={"25"} width={"25"} icon={MenuIcon} smartColor={theme?.accent} className="cursor-pointer" onClick={(e) => {
                    setShowSideMenu(true)
                }} />}
            <span className={"titleText"}>{title}</span>
            <SizedBox width={1} />
        </StyledObject>
    )

}
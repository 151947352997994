import styled from "styled-components";

const StyledElement = styled.div`
  width: ${(props) => (props.size ? `${props.size}rem` : "2rem")};
  height: ${(props) => (props.size ? `${props.size}rem` : "2rem")};
  border-radius: 50%;
  background-color: ${(props) => (props.background_color ? `${props.background_color}` : "var(--color-2)")};
   display:flex;
   align-items: center;
   justify-content: center;
  
  svg{
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const CircularButtonWithIcon = ({size, background_color, children}) => {
    return (
        <StyledElement size={size} background_color={background_color}>
            {children}
        </StyledElement>
    )
}

import styled from 'styled-components';
import PropTypes from 'prop-types'

const StyledComponent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.0625rem;
  width: 100%;


  .logoWrapper {
    width: 3rem;
    height: 3rem;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex-grow: 1;

    .mainText {
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 600;
      color: var(--color-label-300);
    }

    .subText {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: var(--color-label-200);
    }
  }

  .trailingIcon {
    align-self: center;
    
    svg{
      width: 1rem;
      height: 2rem;
    }
  }



`

export const ListItemStyledComponent = ({logoElement, contentMainText, contentSubText, trailingIconElement}) => {
    return (
        <StyledComponent>
            <div className="logoWrapper">
                {logoElement}
            </div>
            <div className="contentWrapper">
                <div className={'mainText'}>{contentMainText}</div>
                <div className={'subText'}>{contentSubText}</div>
            </div>
            {trailingIconElement && <div className={'trailingIcon'}>{trailingIconElement}</div>}
        </StyledComponent>
    )

}

ListItemStyledComponent.propTypes = {
    logoElement: PropTypes.element.isRequired,
    contentMainText: PropTypes.string.isRequired,
    contentSubText: PropTypes.string.isRequired,
    trailingIconElement: PropTypes.element
}
import axios from 'axios'
import depositsLogo from '../../../components/global/components/side_menu/static/logo.PNG'
import API_BASE_URL from '../../../API_BASE_URL'


const axiosThemeInstance = axios.create({
  // baseURL: 'https://client.api.swys.xyz/api/v1',

  baseURL: API_BASE_URL,
})

export const getThemeLogo = async (key) => {
  try {
    const res = await axiosThemeInstance.post('/admin/customization/get', { api_key: key })

    if (res.data.status === 'error' || !res.data?.data?.company_brand_color) {
      const err = new Error()
      err.message = 'invalid token'
      throw err
    }

    return res?.data?.data
  } catch (error) {
    console.log(error);
    // (key !== 'money') && getThemeLogo('money')
    return { company_brand_color: '#0DB9E9', logo: depositsLogo };
  }
}

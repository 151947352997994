import styled from 'styled-components'
import { Icon } from "@deposits/ui-kit-react";
// import logo from './static/logo.PNG'

// import {Home, Cash, Settings, Move, Link} from '@deposits/ui-kit-react/dist/components/icons'
// import { Home, Cash, Settings, Move, Link } from '@deposits/ui-kit-react/dist/components/icons'

import { HomeIcon, MoveIcon, CashIcon, GearIcon, LinkIcon } from '../icons';

import { MenuItem } from "./components/MenuItem";
import { SizedBox } from "../SizedBox";
import { useContext } from 'react';
import { GlobalContext } from '../../../../App';

const StyledObject = styled.div`
display: flex;
flex-directions: rows;
width: 100%;
background-color: red;

.main {
    overflow: none;
    position: absolute;
        background-color: #00000055;                                                                
        width: 100%;
        height:100vh;
        z-index: 100;
        top: 0;
        left: 0;
        bottom: 0;
        right: 20%;
    }
    
    .wrapper{
        height: 100%;
        width: 80%;
        background-color: var(--color-white);
        padding-top: 4.5rem;
        
        .logoWrapper{
            margin-left:2.91625rem;
            
            img{
                max-width:70%;
            }
        }
        
        .navItemsWrapper{
            display: flex;
            flex-direction: column;
            padding-left: 2.875rem;
            padding-right: 1rem;
            gap: 1.625rem;
            
            *{
                text-decoration: none;
            }
        }
    }
`;

const ProfileWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 145px;
    padding: 50px;       



    .image-wrapper {
        width: 49px;
        height: 49px;
        border-radius: 50%;
        background-color: #E1E7EC;
    }
    .info-wrapper{
        margin-left: 17px;
        padding-right:20px;
        .info-head {
            font-weight: 600;
            font-size: 16px;
            color: #1E202A;

        }
        .info-about {
            font-weight: 400;
            font-size: 12px;
            color: #5F6B7A;
        }
        .info-action {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            color: #0A66BA;
        }
    }
    
`



const navList = [
    {
        text: "My balance",
        Icon: HomeIcon,
        to: "/",
    },
    {
        text: "Deposits Account",
        Icon: CashIcon,
        to: "/"
    },
    {
        text: "Linked Accounts",
        Icon: LinkIcon,
        to: "/"
    },
    {
        text: "Move Money",
        Icon: MoveIcon,
        to: "/movemoney",
        highlighted: true
    },
    {
        text: "Settings",
        Icon: GearIcon,
        to: "/"
    }
]


export const SideMenuContainer = () => {
    const { logo } = useContext(GlobalContext);

    return (
        <StyledObject>
            <div className='main'>

                <div className={"wrapper"}>
                    <div className={"logoWrapper"}>
                        <img src={logo} alt='logo' />
                    </div>
                    <SizedBox height={4.740625} />
                    <div className={"navItemsWrapper"}>
                        {navList.map((navConfig) => <MenuItem  {...navConfig} />)}
                    </div>

                    {/* <ProfileWrapper >
                        <div className='image-wrapper'>

                        </div>
                        <div className='info-wrapper'>

                        </div>
                    </ProfileWrapper> */}
                </div>

            </div>
        </StyledObject>
    )

}
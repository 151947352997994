import styled from 'styled-components'
import { Icon, Card } from '@deposits/ui-kit-react'
import { MenuIcon, NotificationBellIcon, AddIcon, ChevronArrowRightIcon } from '@deposits/ui-kit-react'
import { SizedBox } from "../../global/components/SizedBox";
import { CircularButtonWithIcon } from "../../global/components/buttons/CircularButtonWithIcon";
import { useContext } from "react";
import { GlobalContext } from "../../../App";
import { Link } from "react-router-dom";

const StyledObject = styled.div`
    background-color: ${props => props.background};
    width: 100%;
    color: ${({ theme }) => theme?.themedText};
    
    .row-1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3.5rem 1.875rem 1.5rem 1.525rem;
        
        .greetingText{
            align-self: flex-start;
            font-size: 1.125rem;
            line-height: 1.4375rem;
            font-weight: 600;
        }
    }
    
    .row-2{
        display: flex;
        flex-direction: column;
        align-items: center;
        
      
        
        .balanceValue{
            font-size: 2.5rem;
            font-weight: 700;
        }
        
        .balanceLabel{
            margin-top: 0.5625rem;
            font-size: 0.875rem;
            font-weight: 400;
        }
    }
    
    a{
        text-decoration: none;
    }
    .row-3{
        display: flex;
        width: 88.4%;
        background-color: var(--color-white);
        padding: 0.9375rem 1rem 0.875rem 1.25rem;
        align-items: center;
        border-radius: 0.25rem;
        cursor: pointer;
        margin: 0 auto 1.5rem auto;
        
        .descText{
            font-size: 1rem;
            line-height:1.25rem;
            flex-grow: 1;
            font-weight:600;
            color: var(--color-575B68);
        }
        
`;


export const Hero = () => {


    < span className="balanceValue" > $6,790.37</span >
    const { setShowSideMenu, theme, userToken } = useContext(GlobalContext);

    const body = `/${userToken}`
    return (
        <StyledObject background={theme.accent}>
            <div className="row-1">
                <Icon icon={MenuIcon} smartColor={theme.themedText} className="cursor-pointer highlighted-border" onClick={(e) => {
                    setShowSideMenu(true)
                }} />
                <span className={"greetingText"}>Hi, James</span>
                <Icon icon={NotificationBellIcon} smartColor={theme.themedText} className="cursor-pointer" />
            </div>
            <SizedBox height={1.5} />
            <div className="row-2">
                <span className="balanceValue">$6,790.37</span>
                <span className="balanceLabel">Account balance</span>
            </div>
            <SizedBox height={2.4375} />
            <Link to={body + '/topup/enteramount'}>
                <div className="row-3 highlighted-border">
                    <CircularButtonWithIcon background_color={theme?.primaryBlur} size={3}>
                        <Icon icon={AddIcon} smartColor={theme?.accent} />
                    </CircularButtonWithIcon>
                    <SizedBox width={1} />
                    <span className={"descText "}>Top up your account</span>
                    <Icon icon={ChevronArrowRightIcon} smartColor='var(--color-575B68)' />
                </div>
            </Link>
        </StyledObject>
    )

}
import styled from 'styled-components'
import {Body} from "./components/Body";
import {TopNav} from "../../global/components/TopNav";
import {MainWrapper} from "../../global/wrapper/MainWrapper";
import {SizedBox} from "../../global/components/SizedBox";
import {ChevronArrowLeftIcon} from '@deposits/ui-kit-react'
import {useContext} from "react";
import {GlobalContext} from "../../../App";
import {navCommands} from "../../../config/constants";


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: var(--color-white);
`;

export const MoveMoneyDirectDepositsToBankPageContainer = () => {
    const {
        moveMoneyToFriendsState
    } = useContext(GlobalContext);
    return (
        <MainWrapper>
            <>
                <StyledContainer>
                    <TopNav title={`Send $${moveMoneyToFriendsState?.amountToSend || ''}`} buttonConfig={{
                        to: navCommands.GO_BACK,
                        icon: ChevronArrowLeftIcon
                    }}/>
                    <Body/>
                    <SizedBox height={3.125}/>
                </StyledContainer>
            </>
        </MainWrapper>
    )

}
import axios from "axios";


const axiosInstance = axios.create({
    baseURL: 'https://api.apilayer.com',
    timeout: 5000,
    headers: {
        'apikey': process.env.REACT_APP_API_LAYER_API_KEY
    },
});


export const getExchangeRate = async (to, from) => {
    let endpoint = `/exchangerates_data/convert?to=${to}&from=${from}&amount=${1}`
    return await axiosInstance.get(endpoint).then((response) => {
        return response.data
    }).catch((err) => {
        console.log(err)
        return null;
    })
}

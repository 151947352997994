let API_BASE_URL

switch (window.location.origin) {
    case 'http://localhost:3000':
        API_BASE_URL = 'https://api.dev.deposits.dev/api/v1'
        break;
    case 'https://dev.money-kit.deposits.dev':
        API_BASE_URL = 'https://api.dev.deposits.dev/api/v1'
        break;
    case 'https://dev.moneykit.deposits.dev':
        API_BASE_URL = 'https://api.dev.deposits.dev/api/v1'
        break;
    case 'https://money-kit.deposits.dev':
        API_BASE_URL = 'https://api.deposits.dev/api/v1'
        break;
    case 'https://moneykit.deposits.dev':
        API_BASE_URL = 'https://api.deposits.dev/api/v1'
        break;
    case 'https://money-kit.ondeposits.com':
        API_BASE_URL = 'https://api.ondeposits.com/api/v1'
        break;
    case 'https://moneykit.ondeposits.com':
        API_BASE_URL = 'https://api.ondeposits.com/api/v1'
        break;
    default:
        API_BASE_URL = 'https://api.dev.deposits.dev/api/v1'
        break;
}

// switch (process.env.NODE_ENV) {
//   case 'development': API_BASE_URL = 'https://api.dev.deposits.dev/api/v1'
//     break;
//   case 'sandbox': API_BASE_URL = 'https://api.deposits.dev/api/v1'
//     break;
//   case 'production': API_BASE_URL = 'https://api.deposits.com/api/v1'
//     break;
//   default: API_BASE_URL = 'https://api.dev.deposits.dev/api/v1'
//     break;
// }

export default API_BASE_URL
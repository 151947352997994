import styled from 'styled-components'
import bodyImage from '../static/homeBodyImage.png';
import { transactionTypes } from "../../../config/constants";
import { faker } from '@faker-js/faker';
import { Icon } from "@deposits/ui-kit-react";
import { AddIcon, ArrowDownRightIcon, ArrowUpRightIcon } from "@deposits/ui-kit-react";
import { CircularButtonWithIcon } from "../../global/components/buttons/CircularButtonWithIcon";
import moment from "moment";
import { Link } from "react-router-dom";

const StyledObject = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow-y: scroll;
  padding: 1.3125rem 1.5rem;

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .contentWrapper {
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;

      * {
        font-size: 1rem;
        font-weight: 400;
        color: var(--color-label-200);
        line-height: 1.25rem;
        text-decoration: none;
      }

    }

    .recipientsWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 0;
    }
  }

  .imgWrapper {
    width: 100%;
    height: 100%;

    img {
      max-height: 100%;
      max-width: 100%;
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
      padding: 0;
    }
  }

`;


const RecipientStyledComponent = styled.div`
  display: flex;
  padding: 0.75rem 0;
  align-items: flex-start;
  gap: 1rem;
  border-bottom: 1px solid var(--color-primary-bg);

  .description {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex-grow: 1;

    .content {
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 600;
      color: var(--color-label-300);
    }

    .time {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: var(--color-label-200);
    }
  }

  .amount {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: var(--color-label-400);
  }

`

let count = 0
const recipients = []
while (count <= 10) {
  const typeIndex = (Math.floor(Math.random() * 3))
  recipients.push({
    type: Object.values(transactionTypes)[typeIndex],
    reference: function () {
      return (this.type === transactionTypes.TOP_UP ? faker.finance.account(4) : `${faker.name.firstName()} ${faker.name.lastName()}`)
    }
    ,
    amount: function () {
      return `${this.type === transactionTypes.SEND ? '-' : ''}` + faker.finance.amount(300, 900, 2, '$')
    },
    time: `${Math.floor(Math.random() * 10) + 1}:${Math.floor(Math.random() * 4) + 1}0${['pm', 'am'][Math.floor(Math.random() * 2)]}`
  })
  count++
}

const iconMapping = {
  TOP_UP: (<CircularButtonWithIcon background_color={'var(--color-secondary-100)'} size={3}>
    <Icon icon={AddIcon} smartColor={'var(--color-secondary)'} />
  </CircularButtonWithIcon>),
  RECEIVE: (<CircularButtonWithIcon background_color={'var(--color-E0F7EA)'} size={3}>
    <Icon icon={ArrowDownRightIcon} smartColor={'var(--color-00BE4C)'} />
  </CircularButtonWithIcon>),
  SEND: (<CircularButtonWithIcon background_color={'var(--color-FDEBEB)'} size={3}>
    <Icon icon={ArrowUpRightIcon} smartColor={'var(--color-F25855)'} />
  </CircularButtonWithIcon>)
}

const referencePrefixMapping = {
  TOP_UP: 'Top up with',
  RECEIVE: 'From',
  SEND: 'To'
}

export const Body = () => {
  return (
    <StyledObject>
      <div className={'contentWrapper'}>
        <div className={'heading'}>
          <span>{moment().format('ddd, Do, MMMM')}</span>
          <Link to={'#'}>See all</Link>
        </div>
      </div>
      <div className={'recipientsWrapper'}>
        {recipients.map((item, i) => (
          <RecipientStyledComponent key={i}>
            {iconMapping[item.type]}
            <div className="description">
              <span className="content">
                {referencePrefixMapping[item.type]} {item.reference()}
              </span>
              <span className={'time'}>
                {item.time}
              </span>
            </div>
            <div className="amount">
              {item.amount()}
            </div>
          </RecipientStyledComponent>
        ))}
      </div>
    </StyledObject>
  )

}
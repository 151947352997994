import styled from "styled-components";
import { ContentWrapper } from "./ContentWrapper";
import { DeviceFrameset } from "react-device-frameset";
import { GlobalContext } from "../../../App";
import { useContext } from "react";

const StyledContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
    border-radius: 96px;

`;

export const MainWrapper = ({ children }) => {
    const { windowSize } = useContext(GlobalContext)
    return (
        <StyledContainer>
            {windowSize && windowSize > 450 ?
                <DeviceFrameset device="iPhone X" width="25.875rem" height={"56rem"}>
                    <ContentWrapper>
                        {children}
                    </ContentWrapper>
                </DeviceFrameset> :
                <ContentWrapper>
                    {children}
                </ContentWrapper>
            }
        </StyledContainer>
    );
};

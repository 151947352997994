import styled from 'styled-components'
import {Body} from "./components/Body";
import {TopNav} from "../../global/components/TopNav";
import {MainWrapper} from "../../global/wrapper/MainWrapper";
import {Footer} from "../../global/components/BottomNav";
import {SizedBox} from "../../global/components/SizedBox";

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: var(--color-white);
`;

export const MoveMoneyMainPageContainer = () => {
    return (
        <MainWrapper>
            <>
                <StyledContainer>
                    <TopNav title={"Move money"}/>
                    <Body/>
                    <Footer/>
                </StyledContainer>
            </>
        </MainWrapper>
    )

}
import React, { useContext, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { GlobalContext } from '../App';


const NotFound = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/deposits')
  }, [])

  return (
    <div />
  )
}

export default NotFound 
import styled from 'styled-components'
import { Icon } from "@deposits/ui-kit-react";
import { Link } from "react-router-dom";
import { GlobalContext } from '../../../../../App';
import { useContext } from 'react';

const StyledObject = styled.div`
    display: flex;
    gap: 0.875rem;
    align-items: center;
    cursor: pointer;
    
    *{
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 500;
        color: var(--color-label-400);
    }
`;

const StyledIcon = styled.div`
width: 10%;
display: flex;
align-items: center;
justify-content: flex-start;
`


export const MenuItem = ({ text, Icon, to, highlighted }) => {
    const { userToken } = useContext(GlobalContext)

    const home = `/${userToken}`

    return (
        <Link to={to ? (home + to) : home}>
            <StyledObject className={highlighted && 'highlighted-border'}>
                <StyledIcon>
                    <Icon activeColor={'white'} size="25" />
                </StyledIcon>

                <span>{text}</span>
            </StyledObject>
        </Link>
    )
}
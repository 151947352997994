import styled from 'styled-components'
import { Icon } from "@deposits/ui-kit-react";

// import { CashIcon, SettingsIcon, MoveIcon } from '@deposits/ui-kit-react'
import { Link, useLocation } from "react-router-dom";
import { useContext } from 'react';
import { HomeIcon, MoveIcon, CashIcon, GearIcon } from './icons';
import { GlobalContext } from '../../../App';

const StyledObject = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px 20px 30px ;
    width: 100%;
    background-color: var(--color-white);

`;



export const Footer = () => {
    const { theme, userToken } = useContext(GlobalContext);
    const { pathname } = useLocation()
    const home = `/${userToken}`


    return (
        <StyledObject>

            <Link to={home}><HomeIcon activeColor={theme?.accent} isActive={pathname === home || pathname === '/'} size="20" /></Link>

            <Link to={`${home}/movemoney`}><MoveIcon activeColor={theme?.accent} isActive={pathname === `${home}/movemoney`} size="28" /></Link>

            <Link to={home}><CashIcon activeColor={theme?.accent} size="24" /></Link>

            <Link to={home}><GearIcon activeColor={theme?.accent} size="26" /></Link>

        </StyledObject>
    )

}